import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import pic04 from '../assets/images/pic04.jpg'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="You really shouldn't be here." />
        <HeaderGeneric title="You really shouldn't be here." subtitle="你真的不应该在这里。" />
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={pic04} alt="" /></span>
            <h2>Tempus veroeros</h2>
            <p>Cep risus aliquam gravida cep ut lacus amet. Adipiscing faucibus nunc placerat. Tempus adipiscing turpis non blandit accumsan eget lacinia nunc integer interdum amet aliquam ut orci non col ut ut praesent. Semper amet interdum mi. Phasellus enim laoreet ac ac commodo faucibus faucibus. Curae ante vestibulum ante. Blandit. Ante accumsan nisi eu placerat gravida placerat adipiscing in risus fusce vitae ac mi accumsan nunc in accumsan tempor blandit aliquet aliquet lobortis. Ultricies blandit lobortis praesent turpis. Adipiscing accumsan adipiscing adipiscing ac lacinia cep. Orci blandit a iaculis adipiscing ac. Vivamus ornare laoreet odio vis praesent nunc lorem mi. Erat. Tempus sem faucibus ac id. Vis in blandit. Nascetur ultricies blandit ac. Arcu aliquam. Accumsan mi eget adipiscing nulla. Non vestibulum ac interdum condimentum semper commodo massa arcu.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at augue ac velit malesuada varius in nec quam. Sed mattis erat ac eros sagittis, sit amet vulputate libero dictum. Vestibulum consequat at magna eu cursus. Nullam rhoncus efficitur ex. Aenean ipsum augue, gravida in turpis eu, lobortis volutpat neque. In consequat feugiat libero id volutpat. Vestibulum consectetur augue eu ante interdum mollis. Sed vehicula mi interdum augue posuere, eget sodales augue rutrum. Cras vehicula nec nulla ut dignissim. Vestibulum mauris nunc, dictum a pretium eget, sodales nec felis.</p>
            <h2>你真的不应该在这里。</h2>
            <p>You really shouldn't be here.</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
